// extracted by mini-css-extract-plugin
export var close = "ForgotMyPassword-module--close--e9b84";
export var closeLogin = "ForgotMyPassword-module--close-login--8297c";
export var errorMessage = "ForgotMyPassword-module--error-message--d2572";
export var forgotContainer = "ForgotMyPassword-module--forgot-container--ffb6d";
export var forgotModal = "ForgotMyPassword-module--forgot-modal--4498c";
export var forgotMyPassword = "ForgotMyPassword-module--forgot-my-password--45589";
export var forgotWrapper = "ForgotMyPassword-module--forgot-wrapper--8d0cf";
export var grecaptchaBadge = "ForgotMyPassword-module--grecaptcha-badge--cd2dd";
export var hideOnDesktop = "ForgotMyPassword-module--hide-on-desktop--fce74";
export var hideOnMobile = "ForgotMyPassword-module--hide-on-mobile--6fc24";
export var iconAccountDefault = "ForgotMyPassword-module--icon-account-default--0af98";
export var iconArrow = "ForgotMyPassword-module--icon-arrow--16270";
export var iconBrand = "ForgotMyPassword-module--icon-brand--50971";
export var iconBrand1 = "ForgotMyPassword-module--icon-brand1--b11a8";
export var iconBrand2 = "ForgotMyPassword-module--icon-brand2--05163";
export var iconCalculator = "ForgotMyPassword-module--icon-calculator--191d0";
export var iconCamera = "ForgotMyPassword-module--icon-camera--3b751";
export var iconCheckbox = "ForgotMyPassword-module--icon-checkbox--993c9";
export var iconChevron = "ForgotMyPassword-module--icon-chevron--a3bd8";
export var iconDelete = "ForgotMyPassword-module--icon-delete--466cc";
export var iconDisclaimer = "ForgotMyPassword-module--icon-disclaimer--4557f";
export var iconDone = "ForgotMyPassword-module--icon-done--01407";
export var iconDownload = "ForgotMyPassword-module--icon-download--d1bc9";
export var iconEdit = "ForgotMyPassword-module--icon-edit--aee14";
export var iconFacebook = "ForgotMyPassword-module--icon-facebook--d4101";
export var iconFaq = "ForgotMyPassword-module--icon-faq--91970";
export var iconFaqUp = "ForgotMyPassword-module--icon-faq-up--73900";
export var iconFilledHeart = "ForgotMyPassword-module--icon-filled-heart--85327";
export var iconFlag = "ForgotMyPassword-module--icon-flag--24ea8";
export var iconHeart = "ForgotMyPassword-module--icon-heart--c3dea";
export var iconInstagram = "ForgotMyPassword-module--icon-instagram--086e8";
export var iconLog = "ForgotMyPassword-module--icon-log--b14b7";
export var iconLoginArrow = "ForgotMyPassword-module--icon-login-arrow--e8c56";
export var iconLogout = "ForgotMyPassword-module--icon-logout--803f9";
export var iconPassword = "ForgotMyPassword-module--icon-password--f5004";
export var iconPinterestP = "ForgotMyPassword-module--icon-pinterest-p--760da";
export var iconRate15 = "ForgotMyPassword-module--icon-rate-15--99b1c";
export var iconRate25 = "ForgotMyPassword-module--icon-rate-25--c11ac";
export var iconRate35 = "ForgotMyPassword-module--icon-rate-35--5a267";
export var iconRate45 = "ForgotMyPassword-module--icon-rate-45--4c302";
export var iconRate55 = "ForgotMyPassword-module--icon-rate-55--26d7e";
export var iconRating = "ForgotMyPassword-module--icon-rating--e6d52";
export var iconSocial = "ForgotMyPassword-module--icon-social--abe70";
export var iconSocial1 = "ForgotMyPassword-module--icon-social1--fa94b";
export var iconSocial2 = "ForgotMyPassword-module--icon-social2--c85b2";
export var iconStores = "ForgotMyPassword-module--icon-stores--9adc7";
export var iconTrophy = "ForgotMyPassword-module--icon-trophy--189e3";
export var iconUser = "ForgotMyPassword-module--icon-user--ad994";
export var iconUserCircle = "ForgotMyPassword-module--icon-user-circle--3b3b6";
export var iconYoutube = "ForgotMyPassword-module--icon-youtube--846c7";
export var pageWidth = "ForgotMyPassword-module--page-width--877a9";
export var register = "ForgotMyPassword-module--register--c12c0";
export var view = "ForgotMyPassword-module--view--e2429";
export var views = "ForgotMyPassword-module--views--1f948";
export var visible = "ForgotMyPassword-module--visible--f2b44";