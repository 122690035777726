import { Link, navigate } from 'gatsby'
import React, { useState } from 'react'
import cn from 'classnames'

import logoWhite from '~/assets/svg/logo-white.svg'

import * as st from '~/assets/styl/ForgotMyPassword.module.styl'

import useApi from '~/siteApi'

export default function ForgotMyPassword() {
  const [email, setLogin] = useState('')
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  function handleLogin(e) {
    e.preventDefault()
    useApi('get', `auth/recover/${email}`)
      .then(() => {
        navigate('/login')
      })
      .catch(({ message }) => {
        setError(true)
        setErrorMessage(message)
      })
  }

  return (
    <div className={cn(st.forgotContainer, 'page-width')}>
      <Link className={st.closeLogin} to="/login">
        <span className={st.close}></span>
      </Link>
      <div className={st.forgotWrapper}>
        <img src={logoWhite} alt="" />
        <section className={st.forgotModal}>
          <h1>
            Esqueci a <b>senha</b>
          </h1>
          <p>Insira seu email abaixo para o envio da nova senha</p>
          <form onSubmit={handleLogin}>
            <label>
              <span className="icon-user"></span>
              <input
                type="email"
                placeholder="Insira seu e-mail"
                onChange={(e) => setLogin(e.target.value)}
                value={email}
                required
              />
            </label>
            <span className={cn(st.errorMessage, { [st.visible]: error })}>
              {errorMessage}
            </span>
            <button type="submit">Entrar</button>
            <Link className={st.register} to="/login/">
              <span className="icon-login-arrow"></span>
              Voltar
            </Link>
          </form>
        </section>
      </div>
    </div>
  )
}
