import React from 'react'
import { Head } from '~/components'
import ForgotMyPassword from '~/components/ForgotMyPassword'

const ForgotPage = () => {
  return (
    <>
      <Head />
      <ForgotMyPassword />
    </>
  )
}

export default ForgotPage
